<template>
  <div>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      color="deep-purple accent-4"
    ></v-progress-linear>

    <v-btn
      color="blue"
      class="ma-2 white--text"
      block
      v-if="this.$store.state.drive_token && files.length == 0"
      >Empty folder
    </v-btn>

    <file-list
      v-else-if="files.length > 0"
      :files="computedFiles"
      @fetchFiles="getAllFiles"
      @shareFile="shareFile"
      @downloadFile="downloadFile"
      @move="move"
      @deleteFile="deleteFile"
      @filesUploaded="fileUpload"
      @renameFile="renameFile"
    ></file-list>

    <v-btn
      v-else
      color="blue"
      class="ma-2 white--text"
      block
      @click="initLogin"
    >
      Login to Google Drive
      <v-icon right dark> mdi-google-drive </v-icon>
    </v-btn>

    <ShareUserForm :fileId="fileId" />
  </div>
</template>
<script>
import axios from "axios";
import util from "@/util/util";
import fileList from "./file-list";
import ShareUserForm from "./GoogleDrive/share.vue";
export default {
  components: {
    fileList,
    ShareUserForm,
  },
  props: {
    clientId: {
      required: true,
      type: String,
    },
    appId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      data: {},
      scope: "https://www.googleapis.com/auth/drive",
      pickerApiLoaded: false,
      url: null,
      files: [],
      fileId: "",
      loading: false,
    };
  },
  mounted() {
    let drive = document.createElement("script");
    drive.setAttribute("src", "https://apis.google.com/js/api.js");
    document.head.appendChild(drive);
    if (this.$store.state.drive_token) {
      this.getAllFiles({ name: "Root", id: "root" });
    }
    util.event.$on("file-shared", () => {
      this.$store.state.showGoogleDriveShareDialog = false;
    });
    util.event.$off("refreshFileList");
    util.event.$on("refreshFileList", () => {
      this.fetchFiles(
        this.$store.state.stack[this.$store.state.stack.length - 1].id
      );
    });
  },
  methods: {
    initLogin() {
      this.loadPicker();
    },
    loadPicker() {
      window.gapi.load("auth2", { callback: this.onAuthApiLoad });
    },
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.$store.commit("setDriveToken", authResult.access_token);
      }
      this.getAllFiles({ name: "Root", id: "root" });
    },
    onAuthApiLoad() {
      window.gapi.auth2.authorize(
        {
          client_id: this.clientId,
          scope: this.scope,
          immediate: false,
        },
        this.handleAuthResult
      );
    },
    fetchFiles(parentId) {
      let url = `https://www.googleapis.com/drive/v3/files/?fields=*&q='${parentId}'+in+parents`;
      axios({
        method: "get",
        url: url,
        headers: {
          Authorization: "Bearer " + this.$store.state.drive_token,
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.files = res.data.files;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setDriveToken", null);
            }
          }
        });
    },
    getAllFiles(parent) {
      this.loading = true;
      this.$store.commit("addToStack", parent);
      this.fetchFiles(parent.id);
    },
    fileClick(file) {
      if (file.mimeType.includes("folder")) {
        this.getFile(file.id);
        var id = file.id;
        var name = file.name;
        this.breadCrumbs[id] = name;
      } else {
        window.open(file.webViewLink, "_blank");
      }
    },
    shareFile(file) {
      this.fileId = file.id;
      this.$store.state.showGoogleDriveShareDialog = true;
    },
    downloadFile(file) {
      window.open(file.downloadUrl);
    },
    move(source, destination) {
      this.loading = true;
      let url = `https://www.googleapis.com/drive/v3/files/${source.id}?addParents=${destination.id}`;
      axios({
        method: "patch",
        url: url,
        headers: {
          Authorization: "Bearer " + this.$store.state.drive_token,
          Accept: "application/json",
        },
      })
        .then(() => {
          this.fetchFiles(source.parents[0]);
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setDriveToken", null);
            }
          }
        });
    },
    deleteFile(file) {
      this.loading = true;
      let url = `https://www.googleapis.com/drive/v3/files/${file.id}`;
      axios({
        method: "delete",
        url: url,
        headers: {
          Authorization: "Bearer " + this.$store.state.drive_token,
          Accept: "application/json",
        },
      })
        .then(() => {
          util.notify(1, "Successfully Deleted");
          this.fetchFiles(file.parents[0]);
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setDriveToken", null);
            }
          }
        });
    },
    getFile(fileId) {
      let url = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=*`;
      axios({
        method: "get",
        url: url,
        headers: {
          Authorization: "Bearer " + this.$store.state.drive_token,
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.getAllFiles(res.data);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setDriveToken", null);
            }
          }
        });
    },
    fileUpload(files, parents) {
      util.event.$emit("filesUploadedGoogle", files, parents);
    },
    renameFile(file, name) {
      this.loading = true;
      let url = ` https://www.googleapis.com/drive/v3/files/${file.id}`;
      axios({
        method: "patch",
        url: url,
        headers: {
          Authorization: "Bearer " + this.$store.state.drive_token,
          Accept: "application/json",
        },
        data: { name: name },
      })
        .then(() => {
          util.notify(1, "Successfully Renamed");
          this.fetchFiles(file.parents[0]);
        })
        .finally(() => {
          this.$store.commit("toggleRenameDialog", false);
          this.loading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.commit("setDriveToken", null);
            } else {
              util.notify(0, e.response.data.error.message);
            }
          }
        });
    },
  },
  computed: {
    computedFiles() {
      return this.files.map((f) => {
        let downloadUrl;
        if (f.mimeType.includes("google-apps.spreadsheet")) {
          downloadUrl = f.exportLinks["text/csv"];
        } else if (f.mimeType.includes("google-apps.document")) {
          downloadUrl =
            f.exportLinks["application/vnd.oasis.opendocument.text"];
        } else if (f.mimeType.includes("google-apps.presentation")) {
          downloadUrl =
            f.exportLinks["application/vnd.oasis.opendocument.presentation"];
        } else {
          downloadUrl = f.webContentLink;
        }
        return {
          id: f.id,
          folder: f.mimeType.includes("folder"),
          thumbnail: f.thumbnailLink,
          name: f.name,
          createdDateTime: f.createdTime,
          viewLink: f.webViewLink,
          downloadUrl: downloadUrl,
          parents: f.parents,
          mimeType: f.mimeType,
        };
      });
    },
  },
  watch: {
    "$store.state.drive_token": function(n) {
      localStorage.setItem("drive_token", n);
    },
  },
};
</script>
